import request from '@/utils/request'
/**
 * 需求查看
 */
 export function qrcodeLogin(query) {
    return request({
      url: 'literature/demand/',
      method: 'get',
      params: query
    })
  }
